interface ConfigType {
  apiUrl: string;
}

const Config: ConfigType = {
  /**
   * base API url
   */
  apiUrl:
    process.env.SHOPIFY_SANTI_URL ||
    "https://wholesale-sabar-menanti-coffee-roaster.myshopify.com/api/graphql"
};

export default Config;
