import { HttpLink } from "apollo-link-http";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-boost";
import Config from "../config";
import fetch from "unfetch";

const link = new HttpLink({
  uri: Config.apiUrl,
 headers: {
   'X-Shopify-Storefront-Access-Token': '7f22ce3d0640558c68a55fde8f01e722'
  }
});
const cache = new InMemoryCache();

const ApiClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link
});

export default ApiClient;
