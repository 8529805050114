import * as React from "react";
import { Link } from "@reach/router";
import { ReactNode } from "react";
import WholeSales from "./pages/WholeSales";
import {
  Container,
  Navbar,
  NavbarBrand,
} from "bloomer";

import Back from "../assets/back.svg";

import "./Navigation.scss";

interface Props {
  path: string;
  title: string;
}

const Navigation:React.FC<Props> = ({path, children, title}) => {
  return (
    <Navbar className="Navigation">
      <Container className="Navigation__Container">
        <Link to={path}>
          <Back/>
        </Link>

        <h2 className="Navigation__Title">
          {title}
        </h2>
      </Container>
    </Navbar>
  );
};

export default Navigation;
