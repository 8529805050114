import * as React from 'react';
import { useState } from 'react';
import { navigate, Link } from "@reach/router"
import gql from 'graphql-tag';
import { useMutation } from "@apollo/react-hooks";

// import SantiLogo from "../../assets/santi-logo.svg";
// import Back from "../../assets/back.svg";

import { atom, useAtom } from "jotai";
import {cookies} from '../../lib/cookie'

import './HomePage.scss'

import TextLogo from "../../assets/text-logo.svg";

// import PropTypes from 'prop-types';

const CustomerAuth = (props) => {
  return (
      <div className='HomePage'>
        <TextLogo/>
        <section>
          <Link className="HomePage__Btn" to="/wholesales">
            Wholesales
          </Link>
          <a className='HomePage__Btn' href="./buy-coffee">
            Buy Coffee
          </a>
          <Link className="HomePage__Btn" to="/delivery">
            Delivery
          </Link>
          <p className='HomePage__Footer'>
            www.sabarmenanti.coffee | @sabarmenanticoffee
          </p>
        </section>

      </div>
    )
};

export default CustomerAuth;
