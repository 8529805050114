import * as React from "react";
import { FC, ReactElement } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { atom, useAtom } from "jotai";

import { GET_PRODUCTS_BY_COLLECTION } from  '../../apollo/queries/Products'
import { CREATE_CHECKOUT_CART, ADD_VARIANT_TO_CART } from  '../../apollo/mutation/Cart'
import {
  customerCheckout,
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
} from  '../../apollo/mutation/Checkout'
import Page from "../templates/Page";
import {cookies} from '../../lib/cookie'


import Logo from "../../assets/logo.svg";
import SantiLogo from "../../assets/santi-logo.svg";

const Loading = ({color}:{color?: string}) => (
  <div className="Loading" style={{color: color}}>
    Loading 
    <div className="Loading-Dot one">.
    </div>
    <div className="Loadin::g-Dot two">.
    </div>
    <div className="Loading-Dot three">.
    </div>
  </div>
)

const Profile: React.FC<RouteComponentProps> = () => {

  const santiToken = cookies.get('santi-auth-token')
  React.useEffect(() => {
    if(!cookies.get('santi-auth-token')) {
      navigate('/login')
    }
  }, [santiToken])


  return (
    <Page title="Profile" className="Profile Page"> 
      <header>
        Header
      </header>
        Profile Page
      <section>
      </section>
    </Page>
  )
}

export default Profile
