import * as React from 'react';
import { useState } from 'react';
import { navigate, Link } from "@reach/router"
import gql from 'graphql-tag';
import { useMutation } from "@apollo/react-hooks";

import Navigation from '../Navigation';

// import SantiLogo from "../../assets/santi-logo.svg";
// import Back from "../../assets/back.svg";

import { atom, useAtom } from "jotai";
import {cookies} from '../../lib/cookie'

import './HomePage.scss'

// import PropTypes from 'prop-types';

const CustomerAuth = (props) => {
  return (
      <div className='BuyCoffee'>
        <Navigation
          path="/"
          title="Buy Coffee"
        />
      </div>
    )
};

export default CustomerAuth;
