import * as React from 'react';
import { useState } from 'react';
import { navigate, Link } from "@reach/router"
import gql from 'graphql-tag';
import { useMutation } from "@apollo/react-hooks";

import Navigation from '../Navigation';

import { atom, useAtom } from "jotai";
import {cookies} from '../../lib/cookie'

import './DeliveryPage.scss'

const DeliveryPage = (props) => {
  const deliveryPath = "https://www.foodbooking.com/ordering/restaurant/menu?restaurant_uid=e13733eb-78a2-4230-b85e-bd18b412fe31";
  return (
      <div className='DeliveryPage'>
        <Navigation
          path="/"
          title="Delivery"
        />

        <iframe className="DeliveryPage__Frame" src={deliveryPath} />
      </div>
    )
};

export default DeliveryPage;
