import * as React from "react";
import { useState, useEffect } from "react";

import { Router } from "@reach/router";
import HomePage from "./components/pages/HomePage";
import WholeSalesPage from "./components/pages/InFrameWholeSales";
import BuyCoffee from "./components/pages/BuyCofffee";
import DeliveryPage from "./components/pages/DeliveryPage";
import Profile from "./components/pages/Profile";
import LoginPage from "./components/pages/LoginPage";
import NotFoundPage from "./components/pages/NotFound";

import "./Routes.scss";

const Routes = () => {
  return (
    <Router>
      <HomePage path="/" />
      <WholeSalesPage path="wholesales" />
      <BuyCoffee path="buy-coffee" />
      <DeliveryPage path="delivery" />
      <Profile path="profile" />
      <LoginPage path="login" />
      <NotFoundPage default />
    </Router>
  );
};

export default Routes;
