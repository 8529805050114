import * as React from 'react';
import { useState } from 'react';
import { navigate, Link } from "@reach/router"
import gql from 'graphql-tag';
import { useMutation } from "@apollo/react-hooks";

import Navigation from '../Navigation';

import { atom, useAtom } from "jotai";
import {cookies} from '../../lib/cookie'

import './DeliveryPage.scss'

const InFrameWholeSales = (props) => {
  const wholesalesPath = "https://appwholesale.sabarmenanti.coffee/?nav=false";
  return (
      <div className='DeliveryPage'>
        <Navigation
          path="/"
          title="Wholesales"
        />

        <iframe className="DeliveryPage__Frame" src={wholesalesPath} />
      </div>
    )
};

export default InFrameWholeSales;
