import * as React from 'react';
import { useState } from 'react';
import { navigate } from "@reach/router"
import gql from 'graphql-tag';
import { useMutation } from "@apollo/react-hooks";
import {
  createCustomerAccessToken
} from  '../../apollo/mutation/CustomerAccess'

import SantiLogo from "../../assets/santi-logo.svg";
import Back from "../../assets/back.svg";

import { atom, useAtom } from "jotai";
import {cookies} from '../../lib/cookie'

import './LoginPage.scss'

// import PropTypes from 'prop-types';

const CustomerAuth = (props) => {

  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [nonFieldErrorMessage,setNonFieldErrorMessage] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);

  const resetInputFields = () => {
    setPassword('');
    setEmail('');
  };

  const resetErrorMessages = () => {
    setNonFieldErrorMessage( null );
    setEmailErrorMessage( null );
    setPasswordErrorMessage( null );
  };

  const handleSubmit = () => {
    resetErrorMessages();

    if(email === "") {
      setEmailErrorMessage("Email required!");
    }
    if(password === "") {
      setPasswordErrorMessage("Password required!");
    }

    if (email !== "" || password !== "") {

      loginCustomerAccount(email, password)
    }
  };

  // createCustomerAccessToken
  const [customerAccessTokenCreate, {loading: loadingLoginUser, data} ] = useMutation(createCustomerAccessToken, {
    onError: error => {
      console.log('error =>', error)
    },
    onCompleted: data => {
      if (data.customerAccessTokenCreate.customerAccessToken) {
        // setExpireToken(data.customerAccessTokenCreate.customerAccessToken.expiresAt);
        cookies.set( 'santi-auth-token',
        data.customerAccessTokenCreate.customerAccessToken.accessToken,
          {
            expires: new Date(data.customerAccessTokenCreate.customerAccessToken.expiresAt),
          }
        );

        navigate('./wholesales')
      } else {
        data.customerAccessTokenCreate.userErrors.forEach(function (error) {
          if (error.field && error.field === 'email') {
            setEmailErrorMessage( error.message );
          } else if (error.field && error.field === 'password') {
            setPasswordErrorMessage( error.message );
          } else {
            setNonFieldErrorMessage( error.message );
          }
        });
      }
    }
  }); 

  const loginCustomerAccount = (eail, password) => {
    const input = {
      email: email,
      password: password
    }

    customerAccessTokenCreate(
      {variables: {input}}
    )
  }

  React.useEffect(() => {
    if(cookies.get('santi-auth-token')) {
      navigate('./wholesales')
    }
  }, [cookies.get('santi-auth-token')])

  return (
      <div className='CustomerAuth'>
        <a className='CustomerAuth__back'href="./">
          <Back/>
        </a>
        <div className="CustomerAuth__logo">
          <SantiLogo/>
        </div>
        <div className="CustomerAuth__body">
          <h2 className="CustomerAuth__heading">
            Coffee?
            <span>
              Welcome Back
            </span>
          </h2>
          {nonFieldErrorMessage &&
            <div className="error">{nonFieldErrorMessage}</div>
          }
          <label className="CustomerAuth__credential">
            <input className="CustomerAuth__input" type="email" placeholder="Email" name={"email"} value={email} onChange={(event)=>{setEmail(event.target.value)}}></input>
            {emailErrorMessage &&
              <div className="error">{emailErrorMessage}</div>
            }
          </label>
          <label className="CustomerAuth__credential">
            <input className="CustomerAuth__input" type="password" placeholder="Password" name={"password"} value={password} onChange={(event)=>setPassword(event.target.value)}></input>
            {passwordErrorMessage &&
              <div className="error">{passwordErrorMessage}</div>
            }
          </label>
          <button className="CustomerAuth__submit button" type="submit" onClick={handleSubmit}>
            Log in
          </button>
        </div>
      </div>
    )
};

// CustomerAuth.propTypes = {
//   customerCreate: PropTypes.func.isRequired,
//   customerAccessTokenCreate: PropTypes.func.isRequired,
// };
//
// const customerCreate = gql`
//   mutation customerCreate($input: CustomerCreateInput!) {
//     customerCreate(input: $input) {
//       userErrors {
//         field
//         message
//       }
//       customer {
//         id
//       }
//     }
//   }
// `;
//
//
// const CustomerAuthWithMutation = compose(
//   graphql(customerCreate, {name: "customerCreate"}),
//   graphql(customerAccessTokenCreate, {name: "customerAccessTokenCreate"})
// )(CustomerAuth);
//
// export default CustomerAuthWithMutation;
//
export default CustomerAuth;
