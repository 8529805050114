import * as React from "react";
import { ReactNode, ReactElement } from "react";
import { NavbarStart, NavbarItem } from "bloomer";

import Navigation from "../Navigation";
import BottomNavigation from "../BottomNavigation";
import Section from "../Section";
interface Props {
  title: string;
  className: string;
}

const Page: React.FC<Props> = ({ title, className, children }): ReactElement<Props> => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default Page;
